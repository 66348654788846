<template>
  <div class="mt-3 md:mt-0">
    <t-dropdown variant="smActions">
      <template #default="{ hide }">
        <div
          @click="hide()"
          class="py-1 mt-px bg-white rounded-md shadow-md z-200"
        >
          <t-dropdown-item variant="danger" @click="askConfirmation">
            Delete
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>

    <ConfirmFirst
      :id="`confirmFirst-${data.id}`"
      :key="`confirmFirst-${data.id}`"
      :isLoading="isLoading"
      :title="`Delete Template?`"
      :subtitle="
        `All associated data will be deleted. This action cannot be undone.`
      "
      :criticalityLevel="`medium`"
      :criticalityTitle="`Please Type The Delation Code`"
      :criticalityPassword="`ILOVEOTORIDE`"
      :confirm-button-text="`Delete`"
      :confirm-button-variant="`warning-alt`"
      @confirm="executeAction('delete')"
      @cancel="handleCancellation"
    />
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
export default {
  name: 'TemplateMoreActionsDropdown',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
    ConfirmFirst: () => import('@/components/modals/ConfirmFirst'),
  },
  props: {
    data: {
      type: Object,
      required: false,
      default() {
        return {
          status: false,
        }
      },
    },
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    askConfirmation() {
      this.$modal.show(`confirmFirst-${this.data.id}`)
    },
    async executeAction(type) {
      if (type === 'delete') {
        const endpoint = useEndpoints.mail.template.delete(this.data.id)

        this.isLoading = true
        await this.$http
          .delete(endpoint)
          .then((res) => {
            console.log('delete', res.data)

            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Template has been deleted`,
              text: `The template has been deleted successfullhy`,
            })

            this.$emit('delete:success', res.data)
            this.$modal.hide(`confirmFirst-${this.data.id}`)
          })
          .catch((err) => {
            console.error(err)

            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Error Occured [${err.response.status}]`,
              text:
                err.response.data.message ??
                'Could not change template status, please try again.',
            })

            this.$emit('delete:failure', err)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    handleCancellation() {
      console.log('cancelled')
    },
  },
}
</script>

<style lang="scss" scoped></style>
